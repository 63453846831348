import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { withClientState } from 'apollo-link-state'

import { Auth } from '@aws-amplify/auth'

import VueApollo from 'vue-apollo'
import Vue from 'vue'

Vue.use(VueApollo)

if (process.env.IDENTITY_POOL_ID.indexOf('{ Ref') !== 0) {
  Auth.configure({
    identityPoolId: process.env.IDENTITY_POOL_ID,
    region: process.env.SERVICE_REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID
  })
} else {
  console.error('Evironment Variables not correctly exported.')
}

const request = async (operation) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    // someone has requested an operation with a status (no matter which)
    // since we are logged in (and token is not expired)
    // we can request drafts
    if (operation.variables.status) {
      operation.variables.status = 'DRAFT'
      operation.setContext({
        headers: {
          Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`
        }
      })
    }
  } catch (e) {

  }
}

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        })
        return null
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) handle.unsubscribe()
    }
  })
)

const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
}

const cache = new InMemoryCache()

const baseApiURI = process.env.IS_OFFLINE ? `${process.env.API_ENDPOINT.slice(0, -1)}` : ''

const contentClient = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link: ApolloLink.from([
    onError(errorHandler),
    requestLink,
    withClientState({ cache }),
    new BatchHttpLink({
      batchInterval: 100,
      uri: baseApiURI + process.env.CONTENT_API_ENDPOINT
    })
  ]),
  cache
})

export default new VueApollo({ defaultClient: contentClient })
