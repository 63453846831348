<template>
  <v-app id="app" class="app" :class="{ 'scroll': isScrolling }" v-show="!platform.prerenderer && isReady">

    <router-view v-if="isReady && isAllSiteSettingsLoaded && isAllTranslationsLoaded" />

    <!--<VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    />-->

    <!-- Loading -->
    <div v-if="isLoading" class="preloader fullscreen transparent-black">
      <div class="center">Loading</div>
    </div>

  </v-app>
</template>

<script>
// import { VersionInfo } from '@monogrid/vue-lib'
import { Platform } from '@monogrid/js-utils'
import shareImage from '@/assets/img/share.jpg'
import SiteSetting from '@/graphql/GetSiteSetting.gql'
import ListTranslations from '@/graphql/ListTranslations.gql'
import ListHotspotCarousel from '@/graphql/ListHotspotCarousel.gql'
let initializedModernizr = false

export default {
  name: 'App',

  components: {
    // VersionInfo
  },

  data: function () {
    return {
      isReady: false,
      isLoading: false,
      isScrolling: false,
      isAllSiteSettingsLoaded: false,
      isAllTranslationsLoaded: false,
      platform: Platform
    }
  },

  mounted: function () {
    this.isReady = true
    this.checkRoute()
  },

  updated () {
    // if not prerenderer
    if (!Platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      for (const key in Platform) {
        window.Modernizr.addTest(key, () => {
          return Platform[key]
        })
      }
    }
  },

  methods: {
    checkRoute () {
      if (this.$route.query.debug || window.location.hostname.indexOf('localhost') > -1) {
        this.$root.isDebug = true
      }
    }
  },

  watch: {
    $router: function () {
      this.checkRoute()
    },

    isReady: function (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    }
  },

  // Query
  apollo: {
    // Site settings
    SiteSetting: {
      query: SiteSetting,
      async result () {
        this.$root.siteSetting = this.SiteSetting
        // console.log('Settings loaded', this.$root.siteSetting)
        this.isAllSiteSettingsLoaded = true
      }
    },
    // All translations
    ListTranslations: {
      query: ListTranslations,
      async result () {
        this.$root.allTranslations = {}
        for (let i = 0; i < this.ListTranslations.items.length; i++) {
          const element = this.ListTranslations.items[i]
          this.$root.allTranslations[element._slug] = element
        }
        this.isAllTranslationsLoaded = true
        // console.log('Translations loaded:', this.$root.allTranslations)
      }
    },
    // All hotspot carousel
    ListHotspotCarousel: {
      query: ListHotspotCarousel,
      async result () {
        this.$root.allHotspotCarousel = {}
        for (let i = 0; i < this.ListHotspotCarousel.totalCount; i++) {
          const element = this.ListHotspotCarousel.items[i]
          this.$root.allHotspotCarousel[element._slug] = element
        }
        // this.isAllTranslationsLoaded = true
        // console.log('Carousels loaded:', this.$root.allHotspotCarousel)
      }
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: ' ',
      // all titles will be injected into this template
      titleTemplate: '%s | mutti-video',
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@monogridstudio' },
        { name: 'twitter:creator', content: '@monogridstudio' },
        // generic description
        { vmid: 'description', name: 'description', content: 'mutti interactive video' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'mutti-video' },
        { vmid: 'ogdescription', property: 'og:description', content: 'mutti interactive video' },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' }
      ]
    }
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: 'Brushaff';
  font-style: normal;
  src:
    url('assets/fonts/Brushaff.woff2') format('woff2'),
    url('assets/fonts/Brushaff.woff') format('woff');
}

@font-face {
  font-family: 'TradeGothicLTStd';
  font-style: normal;
  src:
    url('assets/fonts/TradeGothicLTStd-Cn18.woff2') format('woff2'),
    url('assets/fonts/TradeGothicLTStd-Cn18.woff') format('woff');
}

html,
body {
  background-color: #fbf6e9 !important;
  overflow: hidden !important;

  .theme--light.v-application {
    font-family: 'TradeGothicLTStd';
    letter-spacing: normal;
    line-height: normal;
    font-weight: 300;
    color: $c-white;
  }
}

.app {
  background-color: #fbf6e9;
  overflow: hidden !important;
}

a {
  text-decoration: none;
}

h1 span,
h1 {
  font-size: 130px;

  @include breakpoint('lg-and-down') {
    font-size: 110px;
    line-height: 0.9;
  }

  @include breakpoint('md-and-down') {
    font-size: 90px;
    line-height: 0.9;
  }

  @include breakpoint('sm-and-down') {
    font-size: 70px;
  }

  @include breakpoint('xs-only') {
    font-size: 50px;
  }
}

h1 span,
h3 span,
h2 span {
  display: inline-block;
}

h2 span,
h2 {
  font-size: 60px;

  @include breakpoint('md-and-down') {
    font-size: 48px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }

  @include breakpoint('xs-only') {
    font-size: 28px;
  }
}

h3 span,
h3 {
  font-size: 48px;

  @include breakpoint('md-and-down') {
    font-size: 36px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 32px;
  }

  @include breakpoint('xs-only') {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;
  letter-spacing: normal;

  @include breakpoint('md-and-down') {
    font-size: 22px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 20px;
  }
}

h5 {
  font-size: 16px;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
}

p {
  font-size: 24px;
  line-height: 1.2;

  @include breakpoint('md-and-down') {
    font-size: 18px;
  }

  @include breakpoint('xs-only') {
    font-size: 18px;
  }
}

span {
  font-size: 12px;
}

.svg-fill-white {
  fill: $c-white;

  path {
    fill: $c-white;
  }
}

.svg-stroke-white {
  stroke: $c-white;

  path {
    stroke: $c-white;
  }
}

.svg-fill-black {
  fill: $c-black;

  path {
    fill: $c-black;
  }
}

.svg-stroke-black {
  stroke: $c-black;

  path {
    stroke: $c-black;
  }
}

.pointer {
  cursor: pointer;
}

.c-black {
  color: $c-black;
}

.brushaff {
  font-family: 'Brushaff';
  font-weight: normal !important;
}

.trade-gothic {
  font-family: 'TradeGothicLTStd-Cn18';
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: lighter;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.extra-light {
  font-weight: 100;
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
}

.text-align-center {
  text-align: center;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-bottom {
  position: absolute;
  bottom: 15%;
  right: 50%;
  transform: translate(50%, 15%);
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.preloader {
  z-index: 10 !important;
}

.transparent-black {
  background-color: $c-black-50;
  color: $c-white;
  z-index: 2;
}

.transparent-red {
  background-color: $c-primary-50;
  color: $c-white;
  z-index: 2;
}

/* Hotspot */
.hotspot-container {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;

  h3 {
    opacity: 0.4;
  }

  .hotspot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transition: all 0.4s ease-in-out;

    .inner {
      opacity: 1;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $c-primary-50;
      transition: all 0.4s ease-in-out;
    }

    .pulse,
    .outline {
      position: absolute;
      top: 40px;
      left: 50%;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  &.first {
    cursor: pointer;

    &:hover h3,
    &:hover .hotspot,
    &:hover .inner {
      transform: scale(1.2);
    }

    h3 {
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }

    .hotspot {
      .inner {
        background-color: $c-primary;
      }

      .outline {
        border: 1px solid $c-primary;
        animation: pulseborder 3s infinite;
      }

      .pulse {
        background-color: $c-primary;
        animation: pulse 3s infinite;
      }
    }
  }
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(0);
    opacity: 1; }

  50% { opacity: 0.5; }

  100% { transform: translate(-50%, -50%) scale(1);
    opacity: 0; }
}

@keyframes jump {
  0% { transform: translateY(0); }

  20% { transform: translateY(-10px); }

  100% { transform: translateY(0); }
}

@keyframes scale {
  0% { transform: scale(1); }

  20% { transform: scale(1.1); }

  100% { transform: scale(1); }
}

@keyframes scalemobile {
  0% { transform: scale(1); }

  20% { transform: scale(1.2); }

  100% { transform: scale(1); }
}

@keyframes pulseborder {
  0% { transform: translate(-50%, -50%) scale(0);
    opacity: 1; }

  40% { opacity: 1; }

  60% { transform: translate(-50%, -50%) scale(1);
    opacity: 0; }

  100% { transform: translate(-50%, -50%) scale(1);
    opacity: 0; }
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 1s $ease-out-quint;
}

.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

.fade-slide-leave-active,
.fade-slide-enter-active {
  transition: opacity 1s $ease-out-quint, transform 1s $ease-out-quint;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0 !important;
  transform: translate(-50%, 10px) !important;
}
</style>
