import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { SchemaLink } from 'apollo-link-schema'
import { makeExecutableSchema } from 'graphql-tools/dist/makeExecutableSchema'
import { addMockFunctionsToSchema } from 'graphql-tools/dist/mock'
import VueApollo from 'vue-apollo'
import Vue from 'vue'
import axios from 'axios'

import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import cloneDeep from 'lodash/cloneDeep'

Vue.use(VueApollo)

const walk = (item, fn, parent, key) => {
  if (!parent) {
    item = fn(item)
  }

  if (isPlainObject(item) && !isString(item)) {
    Object.keys(item).forEach(k => {
      item[k] = fn(item[k])
      walk(item[k], fn, item, k)
    })
  } else if (isArray(item)) {
    for (let i = 0; i < item.length; i++) {
      item[i] = fn(item[i])
      walk(item[i], fn, item, i)
    }
  }

  return item
}

const clone = (item) => {
  const ret = cloneDeep(item)
  return walk(ret, (el) => {
    if (isArray(el) && el.length > 0 && isPlainObject(el[0])) {
      return el.map(e => {
        if (e) {
          return { ___toBeResolved: true, ...e }
        }
        return null
      })
    } else if (isString(el) && el.indexOf('_reference/') === 0) {
      const split = el.split('/')
      return {
        __typename: split[1],
        id: split[2]
      }
    }
    return el
  })
}

// export default new VueApollo({ defaultClient: offlineClient })
export default class CMSOffline {
  /**
   *
   * @param {*} catalogPath
   */
  constructor (catalog, schema) {
    this.catalog = catalog
    this.schema = schema
    this.data = {}
    this.initialized = false
  }

  /**
   *
   * @param {*} list
   */
  async fetchData (list) {
    const result = await axios.get(`${list.data}?hash=${process.env.GIT_COMMIT_HASH}`)
    this.data[list.type] = clone(result.data)
  }

  /**
   *
   * @param {*} list
   */
  getResolver (list) {
    return async (root, { slug, id, status }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) await this.fetchData(list)

      let data
      if (id) {
        // console.log('ID PARAM is set, resolving')
        data = clone(this.data[list.type].find((el) => el.id === id))
      } else if (slug) {
        // console.log('SLUG PARAM is set, resolving')
        data = clone(this.data[list.type].find((el) => el._slug === slug))
      }

      if (data) {
        cache.writeData({ data })
        return data
      }
      return null
    }
  }

  /**
   *
   * @param {*} list
   */
  listResolver (list) {
    return async (root, { sortOn, descending = true, limit, cursor, status, filter }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) await this.fetchData(list)
      const items = clone(this.data[list.type])
      const data = {
        items,
        cursor: null,
        totalCount: items.length
      }

      cache.writeData({ data })

      // console.log('list resolver resolved')
      return data
    }
  }

  /**
   *
   * @param {*} list
   */
  typeResolver (list) {
    return async (root, { slug, id, status }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) await this.fetchData(list)
      // console.log('type resolver')
      const resolvedWithGet = await mockQueries[`Get${list.type}`](root, { slug, id, status }, { cache, mockQueries }, info)
      if (resolvedWithGet) {
        return resolvedWithGet
      } else {
        if (isArray(root[info.fieldName])) {
          // we are part of an array
          // console.log('field is array')

          const ret = root[info.fieldName].find(e => e ? e.___toBeResolved === true : false)
          if (ret) {
            // console.log('array found __toBeResolved')
            ret.___toBeResolved = false
            return ret
          }
          console.error('array __toBeResolved NOT FOUND')
          return root[info.fieldName]
        } else if (root[info.fieldName] && root[info.fieldName].__typename && root[info.fieldName].id) {
          // console.log('WE ARE AN OBJECT, PROBABLUY ALREADY RESOLVED')
          // return root[info.fieldName]
          return mockQueries[`Get${root[info.fieldName].__typename}`](root, { slug: null, id: root[info.fieldName].id, status }, { cache, mockQueries }, info)
        } else {
          throw new Error('Can\'t resolve')
        }
      }
    }
  }

  /**
   *
   */
  getProvider () {
    const mockQueries = {}
    const mockTypes = {}

    this.catalog.lists.forEach(l => {
      mockQueries[`Get${l.type}`] = this.getResolver(l)
      mockQueries[`List${l.type}`] = this.listResolver(l)
      mockTypes[l.type] = this.typeResolver(l)
    })

    const mocks = {
      // scalars
      RepeaterInstance (root, args, context, info) {
        return {}
      },
      // JSON (root, args, context, info) {
      //   return {}
      // },
      // URL (root, args, context, info) {
      //   return 'http://www.ciao.com'
      // },
      // Queries
      Query (root, args, context, info) {
        return mockQueries
      },
      // types
      ...mockTypes
    }

    const schema = makeExecutableSchema({ typeDefs: this.schema })
    addMockFunctionsToSchema({ schema, mocks })

    const cache = new InMemoryCache()

    const offlineClient = new ApolloClient({
      cache,
      link: new SchemaLink({ schema, context: { cache, mockQueries } })
    })

    return new VueApollo({ defaultClient: offlineClient })
  }
}
